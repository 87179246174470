import React from 'react'
import { getComponent } from '../../components'
import { IDefaultProps } from '../../../types'
import './styles.scss'

export default ({ content, data }: IDefaultProps) => {
  const { components } = content
  const secondComponent = components[2]
  const thirdComponent = components[3]
  const uniteComponents =
    secondComponent &&
    secondComponent.slug === 'language-tile' &&
    secondComponent.sectionTitle &&
    thirdComponent.rendering === 'funneling-sub-tiles' &&
    thirdComponent.sectionTitle === ''
  return (
    <div className='funneling-siteroot-template'>
      <div
        className={`funneling__wrapper ${
          secondComponent &&
          secondComponent.sectionTitle &&
          secondComponent.rendering !== 'funneling-sub-tiles'
            ? ''
            : 'three-row-comp-w-section-title'
        } ${uniteComponents ? '--lang-with-subtiles' : ''}`}
      >
        {components.map((component) => {
          return getComponent(component, data)
        })}
      </div>
    </div>
  )
}
