import React, { Component } from 'react'
import { connect } from 'react-redux'
import BannerCombined from './BannerCombined'
import { labelSelector } from '../../redux/selectors'
import { IBanner } from '../../../types'
import { GridContainer, GridRow, GridCol } from '@ilc-technology/cefcom-ui-library'
import './styles.scss'

class USCombinedTileBanner extends Component<IBanner> {
  render() {
    const {
      content,
      content: { sectionTitle, rendering, tiles },
    } = this.props
    return (
      <section
        id={`omnidiv-funneling-us-banner`}
        className={`ef-section rendering__${rendering} funneling-section`}
      >
        <GridContainer>
          <h2 className='ef-section-main-title'>{sectionTitle}</h2>
          <GridRow classNames={['banner-tiles']}>
            {tiles.map((tile, i) => (
              <GridCol key={i} classNames={['-s-12']}>
                <div id={`tile-${i}`}>
                  <BannerCombined {...tile} />
                </div>
              </GridCol>
            ))}
          </GridRow>
        </GridContainer>
      </section>
    )
  }
}
const mapStateToProps = (state) => ({
  labels: labelSelector(state),
})

export default connect(mapStateToProps)(USCombinedTileBanner)
