import React, { Component } from 'react'
import './styles.scss'
import { GridContainer } from '@ilc-technology/cefcom-ui-library'
import Column from './Column'

interface IPreFooter {
  title: string
  columns: string[]
}
class PreFooter extends Component<IPreFooter> {
  render() {
    const { title, columns } = this.props

    return (
      <div className='prefooter'>
        <GridContainer>
          <h2 className='ef-section-main-title'>{title}</h2>
          <div className='prefooter-content'>
            {columns.map((column, i) => {
              const { title, links } = column
              return <Column title={title} links={links} content={column} />
            })}
          </div>
        </GridContainer>
      </div>
    )
  }
}

export default PreFooter
