/**
 * Returns a class or number to fill up the rest of a row based on inputs.
 * @param check {number|array} The number of items or an array of items to use as the basis of a check.
 * @param bigCols {number|boolean} If a number, it is used as a basis for number of layout columns on a large device. If a boolean, the function will return a class.
 * @param medCols {number} The number of layout columns used on a medium device e.g. tablet.
 * @param smallCols {number} The number of layout columns used on a small device.
 * @param totalCols {number|boolean} If a number, it is used as the total number of physical columns available in the document. If a boolean, the function will return a class.
 * @param sendClass {boolean} If true, function returns a class. If false, function returns number of physical columns to fill remaining space.
 */

export const getAvailableGridCols = (check, bigCols = 3, medCols = 2, smallCols = 1, totalCols = 12, sendClass = false) => {

  let isClassRequired;

  check = Array.isArray(check) ? check.length : check;

  if (typeof bigCols === 'boolean') {
    isClassRequired = bigCols;
    bigCols = 3;
  } else {
    isClassRequired = sendClass;
  }

  // Figure out how many layout columns we need to fill...
  let smallRemnant = ((check % smallCols) > 0 ? smallCols - (check % smallCols) : smallCols) * (totalCols / smallCols),
      mediumRemnant = ((check % medCols) > 0 ? medCols - (check % medCols) : medCols) * (totalCols / medCols),
      largeRemnant = ((check % bigCols) > 0 ? bigCols - (check % bigCols) : bigCols) * (totalCols / bigCols);

  return {
    s: isClassRequired ? `-s-${smallRemnant}` : smallRemnant,
    m: isClassRequired ? `-m-${mediumRemnant}` : mediumRemnant,
    l: isClassRequired ? `-l-${largeRemnant}` : largeRemnant
  };

  // -s-12
  // -m-6
  // -l-4
};
