import React, { Component } from 'react';
import {FatFooter} from '@ilc-technology/cefcom-ui-library';
import { IServiceLabel } from '../../../types';

import './styles.scss';

interface IFatFooter {
  content: {
    data: string[],
    rendering: string
    title: string, 
    description: string, 
    anchorText: string,
    anchorUrl: string,
    serviceLabels: IServiceLabel[]
  }
}
class FatFooterWithoutImageRendering extends Component<IFatFooter>{
  render() {
    const { content, content: { title, rendering, description, anchorText, anchorUrl, serviceLabels }} = this.props;

     return (
      <div id={`omnidiv-${rendering}`} className={`ef-section rendering  rendering__${rendering}`} data-clicksubregion="brochure">
        <FatFooter
          title={title}
          description={description}
          label={serviceLabels}
          classNames={['--theme-default']}
          anchorUrl={anchorUrl}
          anchorText={anchorText}
        />
      </div>
    );
  }
}

export default FatFooterWithoutImageRendering;