import React, { Component } from 'react'
import { connect } from 'react-redux'
import Stage from '../../components/Stage'
import { StageTextOnly } from '@ilc-technology/cefcom-ui-library'
import { IStageRendering } from '../../../types'
import { isPageStyleTransparentSelector } from '../../redux/selectors'
import { getNestedVals } from '@ilc-technology/cefcom-utils'
import PromoBanner from '../../components/PromoBanner'
import './styles.scss'

class StageRendering extends Component<IStageRendering> {
  render() {
    const {
      data,
      content,
      content: {
        title,
        subTitle,
        logoImages,
        description,
        labels,
        anchorText,
        anchorUrl,
        stageRendering,
        rendering,
      },
      isTransparent,
      isHomePage,
    } = this.props
    const marketCode = getNestedVals(data, ['metatags', 'marketCode'], null)
    const promotion = getNestedVals(data, ['page', 'promotion'], null)
    const isStageTextOnly =
      rendering === 'stage-text-only' || rendering === 'stage-text-only-w-logo'
    return (
      <section id={`omnidiv-${rendering}`} className={`rendering__${rendering}`}>
        {isStageTextOnly ? (
          <StageTextOnly
            title={title}
            description={description}
            rendering={rendering}
            images={logoImages}
          />
        ) : (
          <Stage
            marketCode={marketCode}
            data={content}
            isTransparent={true}
            title={title}
            description={description}
            labels={labels}
            anchorText={anchorText}
            anchorUrl={anchorUrl}
            stageRendering={stageRendering}
            promotion={promotion}
          />
        )}
        {isStageTextOnly && promotion && promotion.promotionText && (
          <PromoBanner promotionText={promotion.promotionText} classNames={['-standalone']} />
        )}
      </section>
    )
  }
}

const mapStateToProps = (state, { data }) => ({
  data,
  isTransparent: isPageStyleTransparentSelector(state),
})

export default connect(mapStateToProps)(StageRendering)
