import React from 'react'
import './styles.scss'

const PromoBanner = ({ promotionText, classNames }) => (
  <div className={`stage-promo-banner ${classNames}`}>
    <div className='stage-promo-banner__text' dangerouslySetInnerHTML={{ __html: promotionText }} />
  </div>
)

export default PromoBanner
