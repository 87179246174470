import React from 'react'
import { getNestedVals } from '@ilc-technology/cefcom-utils'
import DestinationsCarousel from '../../components/DestinationsCarousel'

interface IOptimizelyUseProgramCardsProps {
  showRecentlyVisitedTag?: boolean
  recentlyVisitedTagLabel?: string
  optimizely?: {
    decision?: {
      variationKey?: string
    }
  }
  data
  rendering
  centralData
}

const OptimizelyUseDestinationCarousel: React.FC<IOptimizelyUseProgramCardsProps> = (props) => {
  const optimizelyUseDestinationCarousel =
    getNestedVals(props, ['optimizely', 'decision', 'variationKey'], 'off') === 'on'

  const { data, rendering, centralData } = props

  return (
    <>
      {optimizelyUseDestinationCarousel && data && (
        <DestinationsCarousel data={data} rendering={rendering} centralData={centralData} />
      )}
    </>
  )
}

export default OptimizelyUseDestinationCarousel
