import React from 'react'
import { getNestedVals, isMobile, logger } from '@ilc-technology/cefcom-utils'
import './styles.scss'

interface ICarouselProps {
  children: JSX.Element[]
  batchSize: number
  showDots: boolean
  videoModal?: boolean
  language?: string
  ariahidden?: boolean
}
interface ICarouselState {
  currentIndex: number
  isMobile: boolean
}

function batch(array, batchSize) {
  let insertIndex = -1

  return array.reduce((result, child, index) => {
    if (index % batchSize === 0) {
      insertIndex++
    }
    result[insertIndex] = result[insertIndex] || []
    result[insertIndex].push(child)
    return result
  }, [])
}

function animateScroll(ref, offsetLeft) {
  const startLeft = ref.scrollLeft
  const endLeft = offsetLeft
  const diff = endLeft - startLeft
  const totalSteps = 50
  const intervalLeft = Math.round(diff / totalSteps)
  let stepCount = 0

  function step() {
    if (stepCount >= totalSteps) {
      ref.scrollLeft = offsetLeft
      return
    }

    setTimeout(() => {
      ref.scrollLeft = startLeft + intervalLeft * stepCount
      stepCount++
      step()
    }, 10)
  }

  step()
}

class Carousel extends React.Component<ICarouselProps, ICarouselState> {
  sliderRef: HTMLDivElement
  constructor(props) {
    super(props)

    this.state = {
      currentIndex: 0,
      isMobile: false,
    }
  }
  componentDidMount() {
    this.setState({
      isMobile: isMobile(),
    })
  }
  moveToIndex(toIndex) {
    const ref = this[`ref${toIndex}`]

    if (!this.sliderRef || !ref) {
      return
    }
    animateScroll(this.sliderRef, ref.offsetLeft)
    this.setState({
      currentIndex: toIndex,
    })
  }

  handlePrevClick() {
    this.moveToIndex(this.state.currentIndex - 1)
  }

  handleNextClick() {
    this.moveToIndex(this.state.currentIndex + 1)
  }

  curryDotClick(index) {
    return () => this.moveToIndex(index)
  }

  renderBatches() {
    return batch(this.props.children, this.props.batchSize).map((batch, index) => {
      const centredBatch = getNestedVals(this.props, ['children'])

      return (
        <div
          key={index}
          className={`carousel-batch ${centredBatch.length < 4 ? '-carousel-centred' : ''}`}
          ref={(comp) => {
            this[`ref${index}`] = comp
          }}
        >
          {batch}
        </div>
      )
    })
  }

  renderDots() {
    if (!this.props.showDots) {
      return null
    }
    return (
      <div className='carousel-dots'>
        {batch(this.props.children, this.props.batchSize).map((batch, index) => {
          const active = this.state.currentIndex === index
          if (active) {
            return (
              <div
                key={index}
                className={`carousel-dot ${active ? 'carousel-dot__active' : ''} ${
                  (!this.props.videoModal && this.props.children.length <= 4) ||
                  (this.props.videoModal && this.props.children.length <= 1)
                    ? 'carousel-dot__hide'
                    : ''
                }`}
              />
            )
          }

          return (
            <div
              key={index}
              className={`carousel-dot ${
                (!this.props.videoModal && this.props.children.length <= 4) ||
                (this.props.videoModal && this.props.children.length <= 1)
                  ? 'carousel-dot__hide'
                  : ''
              }`}
            />
          )
        })}
      </div>
    )
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.language !== this.props.language) {
      this.setState({ currentIndex: 0 })
    }
  }
  render() {
    const { children, videoModal, language, batchSize, ariahidden } = this.props
    if (!children || !children.length) {
      logger(
        this.props,
        'No Children found (Probably the query logic did not produce anything for the carousel to show) ln155 ~/src/components/custom/shared/Carousel/index.tsx',
        'CAROUSEL',
        'err',
      )
      return null
    }
    return (
      <div
        className={`carousel-root ${videoModal ? '-video-root' : ''} ${
          ariahidden ? '-root-hide' : ''
        }`}
      >
        <div
          className='carousel-slide-container'
          ref={(comp) => {
            this.sliderRef = comp
          }}
        >
          <div
            className={`carousel-slide ${videoModal ? '-slide-video' : ''}`}
            style={{
              minWidth:
                +(videoModal
                  ? !this.state.isMobile
                    ? children.length * 100
                    : children.length * 80
                  : children.length * 33.33) + '%',
            }}
          >
            {this.renderBatches.call(this)}
          </div>
        </div>
        <div
          className={`carousel-cta carousel-cta-prev ${
            this.state.currentIndex === 0 ||
            (!videoModal && children.length <= (!language ? 3 : 4)) ||
            (videoModal && children.length <= 1)
              ? 'carousel-cta__hide'
              : ''
          }`}
          onClick={this.handlePrevClick.bind(this)}
        >
          <span className={'carousel-chevron carousel-chevron-left'} />
        </div>
        <div
          className={`carousel-cta carousel-cta-next ${
            this.state.currentIndex === Math.ceil(children.length / batchSize) - 1 ||
            (!videoModal && children.length <= (!language ? 3 : 4)) ||
            (videoModal && children.length <= 1)
              ? 'carousel-cta__hide'
              : ''
          }`}
          onClick={this.handleNextClick.bind(this)}
        >
          <span className={'carousel-chevron carousel-chevron-right'} />
        </div>
        {this.renderDots.call(this)}
      </div>
    )
  }
}

export default Carousel
