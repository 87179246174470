import React, { Component } from 'react'
import { LazyResponsiveImage } from '@ilc-technology/cefcom-ui-library'
import { isDesktop } from '@ilc-technology/cefcom-utils'
import { connect } from 'react-redux'
import { selectProgramCode } from '../../../redux/actions'
import './styles.scss'

interface IFunnelingCardPopUp {
  slug: string
  tabTitle: string
  title: string
  subTitle: string
  description: string
  subRendering: string
  images: string[]
  tileLinks: string
  studyTypes: string[]
  tileAnchorText: string
  tileAnchorUrl: string
  tileAnchorDescription: string
  isExternalLink: boolean
  rendering: string
}
class FunnelingCardPopUp extends Component<IFunnelingCardPopUp> {
  constructor(props) {
    super(props)

    this.state = {
      clicked: false,
      minHeight: 204,
      clickedSubTile: false,
    }

    this.addClass = this.addClass.bind(this)
    this.removeClass = this.removeClass.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  addClass() {
    const height = this.divElement.clientHeight
    this.setState({
      clicked: true,
      minHeight: height,
      clickedSubTile: true,
    })
  }

  removeClass() {
    this.setState({
      clicked: false,
      clickedSubTile: false,
    })
  }

  handleClick(subTile) {
    const { code, programCodes } = subTile
    const upperProgramCodes = programCodes.toUpperCase()
    this.props.selectProgramCode(code, upperProgramCodes)
  }

  render() {
    const { subTile } = this.props

    const imgheightDefault = '450'
    const imgHeightMobile = '204'
    const imgWidthDefault = '1200'
    const imgWidthMobile = '377'

    return (
      <div
        className={`ef-section funneling-card-section tile-lang-single-link`}
        data-hover-info={subTile['slug']}
      >
        <div
          className={`btns-action`}
          onClick={!isDesktop() && this.state.clicked ? this.removeClass : this.addClass}
        >
          <span className={` ${!isDesktop() && this.state.clicked ? 'icon-close' : 'icon-more'}`} />
        </div>
        <div
          className={`ef-card--teaser funneling-card 
        ${!isDesktop() && this.state.clicked ? 'card-clicked' : ''}`}
          onClick={this.addClass}
        >
          <div>
            <LazyResponsiveImage
              alt={subTile.title}
              classNames={['ef-card--teaser__img']}
              responsiveImages={subTile.images}
              heightDefault={imgheightDefault}
              heightMobile={imgHeightMobile}
              widthDefault={imgWidthMobile}
              widthMobile={imgWidthDefault}
            />
          </div>

          <div className='ef-card--teaser__inner'>
            {subTile.title && (
              <h2
                className='ef-card--teaser__title'
                dangerouslySetInnerHTML={{ __html: subTile.title }}
              />
            )}
          </div>

          <div className={`funneling-card__hover ${subTile.subTitle ? 'with-subtitle' : ''}`}>
            <div
              className='cefcom-row funneling-card__row row-tile-lang-single-link'
              ref={(divElement) => (this.divElement = divElement)}
            >
              <div className={`cefcom-col -s-12 funneling-card__item`}>
                <div className=''>
                  {subTile.subTitle && (
                    <h2 className='funneling-card__subtitle'>{subTile.subTitle}</h2>
                  )}
                  {subTile.hoverSection.hoverDescription && (
                    <div
                      className='funneling-card__description'
                      dangerouslySetInnerHTML={{ __html: subTile.hoverSection.hoverDescription }}
                    />
                  )}
                </div>
              </div>

              <div className={`cefcom-col -s-12 funneling-card__item`}>
                <div className='funneling-card__btns'>
                  {
                    // TODO: This needs to utilise ExternalLink at some point.
                    subTile.anchorText && subTile.anchorUrl && subTile.anchorUrl.includes('#') && (
                      <a
                        href={subTile.anchorUrl + subTile.code}
                        className={`ef-button -secondary -filled -square`}
                        onClick={
                          subTile.anchorUrl.includes('#')
                            ? (e) => {
                                e.preventDefault()
                                this.handleClick(subTile)
                              }
                            : ''
                        }
                        data-clicksubregion='program-by-category'
                      >
                        {subTile.anchorText}
                      </a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  selectProgramCode: (code, programCodes) => dispatch(selectProgramCode(code, programCodes)),
})
export default connect(mapStateToProps, mapDispatchToProps)(FunnelingCardPopUp)
