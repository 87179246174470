import React, { Component } from 'react'
import { branch, compose, renderComponent } from 'recompose'
import { LazyResponsiveImage } from '@ilc-technology/cefcom-ui-library'
import GridCol from '@ilc-technology/cefcom-gud-core/build/grid-col'
import { isDesktop, getNestedVals } from '@ilc-technology/cefcom-utils'
import { ISubTile } from '../../../../types'
import { TeaserCard } from '@ilc-technology/cefcom-teaser-card'
import './styles.scss'

class USFunnelingCard extends Component<ISubTile> {
  constructor(props) {
    super(props)

    this.state = {
      clicked: false,
      minHeight: 204,
    }

    this.addClass = this.addClass.bind(this)
    this.removeClass = this.removeClass.bind(this)
  }

  addClass() {
    const height = this.divElement.clientHeight
    this.setState({
      clicked: true,
      minHeight: height,
    })
  }

  removeClass() {
    this.setState({
      clicked: false,
    })
  }

  render() {
    const {
      slug,
      title,
      subTitle,
      images,
      anchorText,
      anchorUrl,
      isExternal,
      rendering,
      hoverSection,
      buttonLinks,
      typeOfTile,
    } = this.props

    const mosaicFirstTallTile = typeOfTile === 'us-first-sub-tile'
    const mosaicSecondTallTile = typeOfTile === 'us-second-sub-tile'

    const imgheightDefault = mosaicFirstTallTile || mosaicSecondTallTile ? '400' : '256'
    const imgHeightMobile = '204'
    const imgWidthDefault = mosaicFirstTallTile ? '792' : '384'
    const imgWidthMobile = '377'
    const description = getNestedVals(hoverSection, ['hoverDescription'], '')
    return (
      <div
        className={`default-tile-wrapper ${
          mosaicFirstTallTile ? '-tablet-mosaic-tall-tile -mosaic-tall-tile ' : ''
        } ${mosaicSecondTallTile ? '-mosaic-tall-tile ' : ''}`}
      >
        <a
          href={anchorUrl}
          target={`${isExternal ? '_blank' : '_self'}`}
          rel={`${isExternal ? 'noopener' : ''}`}
          data-clicksubregion='program-by-category'
        >
          {buttonLinks && buttonLinks.length ? (
            <div className='age-group-card'>
              <TeaserCard
                {...this.props}
                classNames='-top-text'
                heightDefault={imgheightDefault}
                heightMobile={imgHeightMobile}
                widthDefault={imgWidthMobile}
                widthMobile={imgWidthDefault}
                dataClickSubregion='program-by-category'
              />
            </div>
          ) : (
            <div
              className={`default-tile  ${
                mosaicFirstTallTile || mosaicSecondTallTile ? '--simplified' : ''
              }`}
              data-info={slug}
              data-rendering={rendering}
            >
              <LazyResponsiveImage
                alt={title}
                classNames={['ef-card--teaser__img']}
                responsiveImages={images}
                heightDefault={imgheightDefault}
                heightMobile={imgHeightMobile}
                widthDefault={imgWidthMobile}
                widthMobile={imgWidthDefault}
              />
              <div className='default-tile__content'>
                {title && (
                  <h2 className='default-tile__title' dangerouslySetInnerHTML={{ __html: title }} />
                )}
                {description && (
                  <div
                    className='default-tile__description'
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </div>
              <div className='default-tile__icon-wrapper'>
                {isExternal ? <div className='default-tile__icon' /> : ''}
              </div>
            </div>
          )}
        </a>
      </div>
    )
  }
}

export default compose(branch((...images) => !images || !images.length, renderComponent(<span />)))(
  USFunnelingCard,
)
