import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectProgramCode } from '../../redux/actions'
import { ProgramCardHorizontal } from '@ilc-technology/cefcom-program-card'
import { IUSPGCard } from '../../../types'
import './styles.scss'
import { getNestedVals } from '@ilc-technology/cefcom-utils'
import { GridCol, GridRow, GridContainer } from '@ilc-technology/cefcom-ui-library'
class USPGCard extends Component<IUSPGCard> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      content,
      content: { title, subTitle, rendering },
      serviceLabels,
    } = this.props

    const pgCards = getNestedVals(this.props, ['data', 'programcards', 'availableProgramCards'], [])

    return (
      <React.Fragment>
        <section
          id={`omnidiv-${rendering}`}
          className={`ef-section rendering__program-cards -${rendering} -no-bottom-padding`}
        >
          <div className={`program-cards-top`}>
            <GridContainer>
              <GridRow>
                <GridCol classNames={['-sm-12', '-m-12']}>
                  <a href='javascript: history.go(-1)' className={'link-back'}>
                    Back
                  </a>
                </GridCol>
              </GridRow>
            </GridContainer>
          </div>
          <GridContainer>
            <GridRow classNames={['program-cards__header-row']}>
              <GridCol classNames={['-sm-12', 'program-cards__header-col']}>
                <div className='program-cards__header'>
                  <p
                    className='program-cards__header-label'
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                  <h2
                    className='program-cards__header-title'
                    dangerouslySetInnerHTML={{ __html: subTitle }}
                  />
                </div>
              </GridCol>
            </GridRow>
          </GridContainer>
          <div className='section-wrapper'>
            <GridContainer>
              <GridRow>
                {pgCards.map((card, i) => {
                  card.showAgeDifferentiator = true
                  return (
                    <React.Fragment key={card.code}>
                      <ProgramCardHorizontal {...card} />
                    </React.Fragment>
                  )
                })}
              </GridRow>
            </GridContainer>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  selectProgramCode: (code, programCodes) => dispatch(selectProgramCode(code, programCodes)),
})

export default connect(mapStateToProps, mapDispatchToProps)(USPGCard)
