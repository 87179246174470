import React, {Component} from 'react';
import PreFooter from '../../components/PreFooter';


interface IPreFooter {
  content: {
    showPreFooter: boolean,
    columns: string[],
    slug: string,
    title: string,
    rendering: string
  }
}

class PreFooterRendering extends Component<IPreFooter>{
  render() {
    const { content, content: { title, rendering, showPreFooter, columns }} = this.props;
    return (
      <React.Fragment>
        {content && content.showPreFooter ? 
          <section  data-clicksubregion="omnidiv-pre-footer" id={`omnidiv-${rendering}`} className={`ef-section rendering__${rendering}`}>
            <PreFooter columns={columns} title={title} />
          </section> : '' 
        }
      </React.Fragment>

    );
  }
}

export default PreFooterRendering;
