/* eslint-disable no-console */
import React from 'react'
import Carousel from '../Carousel'
import {
  getNestedVals,
  logger,
  imageAdapter,
  throwError,
  getLabelByName,
} from '@ilc-technology/cefcom-utils'
import { fireClickMap } from '../../utils'

import './styles.scss'
import {
  ResponsiveImage,
  GridContainer,
  GridCol,
  GridRow,
  ICentraData,
} from '@ilc-technology/cefcom-ui-library'
interface IDestinationsCarouselProps {
  rendering: string
  centralData: ICentraData
  data: {
    isDestinationCarousel: boolean
    noFilters?: boolean
    title: string
    defaultLang
    items: {
      slug: string
      anchorUrl: string
      anchorText: string
      title: string
      destinations: {
        title: string
        subTitle: string
        url: string
        images: string[]
      }[]
    }[]
  }
}
interface IDestinationsCarouselState {
  language?: string
  hasError: boolean
}
class DestinationsCarousel extends React.Component<
  IDestinationsCarouselProps,
  IDestinationsCarouselState
> {
  private containerRef: React.RefObject<HTMLUListElement>
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()

    this.state = {
      hasError: false,
      language: getNestedVals(props, ['data', 'defaultLang'], ''),
    }
  }

  componentDidMount() {
    fireClickMap()
  }
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.data !== this.props.data || nextState.language !== this.state.language
  }

  renderItems = (lang, isVisible) => {
    return lang && lang.destinations && lang.destinations.length
      ? lang.destinations.map((item, i) => {
          if (!item) {
            this.setState = () => ({
              hasError: true,
            })
            logger(item, 'No items found', 'destination carousel', 'err')
            return throwError('No items found', item)
          }

          if (!item.images) {
            this.setState = () => ({
              hasError: true,
            })
            logger(item, 'This item does not have images', 'destination carousel', 'err')
            return throwError('This item does not have images', item)
          }
          const drilldownImage = imageAdapter(item.images ? item.images : null)
          return (
            <div
              key={i}
              className='feature-pages-list__item'
              data-cy='feature-pages-list'
              aria-hidden={!isVisible}
            >
              <a href={item.url}>
                <div className='img-holder__wrapper'>
                  <ResponsiveImage
                    className={['drilldown-img']}
                    responsiveImages={drilldownImage}
                    widthDefault={'auto'}
                    widthMobile={'auto'}
                    noLazyLoad={true}
                    dimensions={{
                      mobile: '435x672',
                      desktop: '435x672',
                    }}
                    alt={item.altTextImage}
                  />
                </div>
                <div className='gradient'>
                  <div className='img-holder__text'>
                    <span>{item.subTitle}</span>
                  </div>
                </div>
              </a>
            </div>
          )
        })
      : null
  }

  handleClick = (event, i) => {
    const tab = event.target
    const container = this.containerRef.current
    const containerWidth = container.offsetWidth
    const tabWidth = tab.offsetWidth
    const tabLeft = tab.offsetLeft
    const tabCenter = tabLeft - (containerWidth - tabWidth) / 2

    // Add a smooth transition to the scrollLeft property
    container.style.scrollBehavior = 'smooth'

    container.scrollLeft = tabCenter

    // Remove the smooth transition after a short delay
    setTimeout(() => {
      container.style.scrollBehavior = 'unset'
    }, 500)

    this.setState({ language: i.slug })
  }

  componentDidUpdate() {
    fireClickMap()
  }

  render() {
    const { language, hasError } = this.state
    const { items, title, noFilters } = this.props.data
    const { data, rendering } = this.props
    const { isDestinationCarousel } = data

    if (!items || !language) {
      // No data available to this component.
      // Do not render
      logger({ language, data, items }, 'ERROR DESTINATION CAROUSEL DATA MISSING', '', 'err')
      return null
    }

    return hasError ? null : (
      <>
        <section
          id={isDestinationCarousel ? 'rendering__explore-destination' : null}
          className={`cefcom-section section-wrapper rendering__explore-destination ${
            noFilters ? ' -no-filters' : ''
          }`}
          data-clicksubregion='explore-destination'
        >
          <GridContainer>
            <GridRow>
              <GridCol classNames={['explore-destination__header', '-s-12', '-m-12', '-l-12']}>
                <h2 className='ef-section-main-title'>{title}</h2>
                {rendering === 'single-carousel' ? null : (
                  <div className='destinations__list'>
                    <ul ref={this.containerRef}>
                      {items.map((lang, i) => {
                        return (
                          lang && (
                            <li
                              className={language === lang.slug ? 'selected-destination' : ''}
                              onClick={(event) => this.handleClick(event, lang)}
                              data-info={lang.slug}
                              key={i}
                            >
                              {lang.title}
                            </li>
                          )
                        )
                      })}
                    </ul>
                  </div>
                )}
                <section className='rendering__feature-page-list'>
                  <div className='feature-pages-list__wrapper'>
                    {items.map((lang) => {
                      const isVisible = language === lang.slug
                      return (
                        <Carousel
                          key={lang.slug}
                          batchSize={4}
                          showDots={true}
                          language={language}
                          ariahidden={!isVisible}
                        >
                          {this.renderItems(lang, isVisible)}
                        </Carousel>
                      )
                    })}
                  </div>
                </section>
              </GridCol>
            </GridRow>
          </GridContainer>
        </section>
      </>
    )
  }
}

export default DestinationsCarousel
