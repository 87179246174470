import React, { Component } from 'react'
import { branch, compose, renderComponent } from 'recompose'
import { LazyResponsiveImage, GridCol } from '@ilc-technology/cefcom-ui-library'
import { isDesktop } from '@ilc-technology/cefcom-utils'
import { IBannerTiles } from '../../../../types'
import './styles.scss'

class FunnelingCardCombined extends Component<IBannerTiles> {
  constructor(props) {
    super(props)

    this.state = {
      clicked: false,
      minHeight: 204,
    }

    this.addClass = this.addClass.bind(this)
    this.removeClass = this.removeClass.bind(this)
  }

  addClass() {
    const height = this.divElement.clientHeight
    this.setState({
      clicked: true,
      minHeight: height,
    })
  }

  removeClass() {
    this.setState({
      clicked: false,
    })
  }

  render() {
    const {
      title,
      subTitle,
      images,
      description,
      studyTypes,
      tileAnchorText,
      tileAnchorUrl,
      tileAnchorDescription,
      subRendering,
      isExternalLink,
      slug,
    } = this.props

    const imgheightDefault = '420'
    const imgHeightMobile = '204'
    const imgWidthDefault = '1200'
    const imgWidthMobile = '377'

    return (
      <div className={`ef-section funneling-card-section ${subRendering}`} data-hover-info={slug}>
        {/* {isMobile() ? <span className="icon-more" /> : ''} */}
        <div
          className={`btns-action`}
          onClick={!isDesktop() && this.state.clicked ? this.removeClass : this.addClass}
        >
          <span className={` ${!isDesktop() && this.state.clicked ? 'icon-close' : 'icon-more'}`} />
        </div>

        <div
          className={`ef-card--teaser funneling-card funneling-card-combined
        ${!isDesktop() && this.state.clicked ? 'card-clicked' : ''}`}
          onClick={this.addClass}
        >
          <div>
            <LazyResponsiveImage
              alt={title}
              classNames={['ef-card--teaser__img']}
              responsiveImages={images}
              heightDefault={imgheightDefault}
              heightMobile={imgHeightMobile}
              widthDefault={imgWidthMobile}
              widthMobile={imgWidthDefault}
            />
          </div>
          <div className='ef-card--teaser__inner'>
            {title && (
              <h2 className='ef-card--teaser__title' dangerouslySetInnerHTML={{ __html: title }} />
            )}
          </div>

          {studyTypes ? (
            <div
              className={`funneling-card__hover combined-tile ${subTitle ? '-with-subtitle' : ''}`}
            >
              {subTitle && <h2 className='funneling-card__subtitle'>{subTitle}</h2>}
              <div
                className={'cefcom-row funneling-card__row'}
                ref={(divElement) => (this.divElement = divElement)}
              >
                {studyTypes && studyTypes.length
                  ? studyTypes.map((studyType, i) => {
                      return (
                        <GridCol
                          key={i}
                          classNames={[
                            `-s-12 -m-6 funneling-card__item combined-tile__item item-${i + 1}`,
                          ]}
                        >
                          {studyType.title && (
                            <div className='block-text'>
                              {studyType.anchorUrl ? (
                                <h2 className='block-text__title'>
                                  <a
                                    href={studyType.anchorUrl}
                                    target={`${studyType.isExternal ? '_blank' : '_self'}`}
                                    rel={`${studyType.isExternal ? 'noopener' : ''}`}
                                    data-clicksubregion='program-by-category'
                                  >
                                    {studyType.title}
                                  </a>
                                </h2>
                              ) : (
                                <h2 className='block-text__title'>{studyType.title}</h2>
                              )}
                            </div>
                          )}
                          {studyType.differentiatorText && (
                            <span className='funneling-card__differentiator'>
                              <span className={`ef-icon ${studyType.differentiatorIcon}`} />
                              {studyType.differentiatorText}
                            </span>
                          )}
                          {studyType.description && (
                            <div className='block--rich-text'>
                              <div
                                className='rich-text__content'
                                dangerouslySetInnerHTML={{ __html: studyType.description }}
                              />
                            </div>
                          )}

                          <div className='funneling-card__btns'>
                            {
                              // TODO: This needs to utilise ExternalLink at some point.
                              studyType.anchorText && studyType.anchorUrl && (
                                <a
                                  key={i}
                                  href={studyType.anchorUrl}
                                  className={`ef-button -secondary -filled -square-blue `}
                                  target={`${studyType.isExternal ? '_blank' : '_self'}`}
                                  rel={`${studyType.isExternal ? 'noopener' : ''}`}
                                >
                                  {studyType.anchorText}
                                  {studyType.isExternal && (
                                    <span
                                      className='ef-icon -new-window'
                                      data-clicksubregion='program-by-category'
                                    />
                                  )}
                                </a>
                              )
                            }

                            {studyType.ageCategories
                              ? studyType.ageCategories.map((ageCategorie, i) => {
                                  return (
                                    <a
                                      key={i}
                                      href={ageCategorie.linkUrl}
                                      className={'ef-button -secondary -filled -square'}
                                      data-clicksubregion='program-by-category'
                                    >
                                      {ageCategorie.linkText}
                                    </a>
                                  )
                                })
                              : null}
                          </div>
                        </GridCol>
                      )
                    })
                  : null}
                {tileAnchorText && tileAnchorText.length ? (
                  <div className='funneling-card__bottom-link'>
                    {tileAnchorDescription && (
                      <span dangerouslySetInnerHTML={{ __html: tileAnchorDescription }} />
                    )}
                    {tileAnchorText && tileAnchorUrl && (
                      <a
                        href={tileAnchorUrl}
                        className={`ef-link`}
                        target={`${isExternalLink ? '_blank' : '_self'}`}
                        rel={`${isExternalLink ? 'noopener' : ''}`}
                        data-clicksubregion='program-by-category'
                      >
                        {tileAnchorText}
                      </a>
                    )}
                    {isExternalLink && <span className='ef-icon -new-window' />}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default compose(
  branch(({ images }) => !images || !images.length, renderComponent(<span />)),
)(FunnelingCardCombined)
