import React from 'react'
import {getComponent} from '../components/index'
import Layout from '../components/Layout'
import '../components/BaseStyles/main.scss';

const IndexPage = ({ pageContext}) => {
  const {bucket, slug, data, infoDate} = pageContext
  const {page} = data
  return (
    <div id="app">
      <Layout data={data} infoDate={infoDate}>
        {getComponent(page, data)}
      </Layout>
    </div>
  )
}

export default IndexPage