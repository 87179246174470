import React, { Component } from 'react'
import { connect } from 'react-redux'
import FunnelingCard from './FunnelingCard'
import { GridRow } from '@ilc-technology/cefcom-ui-library'
import { getLabel, getNestedVals } from '@ilc-technology/cefcom-utils'
import { IFunnelingCard, IServiceLabel } from '../../../types'
import { selectServiceLabels } from '../../redux/selectors'
import { SIMPLIFIED_MARKETS } from '../../constants'

import './styles.scss'
class FunnelingComponent extends Component<IFunnelingCard, IServiceLabel> {
  render() {
    const {
      data,
      content: { rendering, tiles },
      serviceLabels,
    } = this.props

    // check to see which type of language tile it is - if it's tile-lang-single-link and
    // it's after a set o 3 subtiles, then it should have a different position on tablet (on the right)
    // this combination is set as [0]stage [1]age-categories [2]sub-tiles [3]
    const langTileSingleLink =
      tiles && tiles[0] && tiles[0].subRendering === 'tile-lang-single-link'
    const langTileRightPosition =
      getNestedVals(data, ['page', 'components', '[3]', 'rendering'], null) === 'funneling-tiles'
    const checkTabletPosition = langTileSingleLink && langTileRightPosition

    const mc = getNestedVals(data, ['app', 'site', 'marketCode']).toLowerCase()
    const isLanguageTile =
      getNestedVals(data, ['page', 'components', '[1]', 'slug']) === 'language-tile'
    const firstComponentLanguage = isLanguageTile && SIMPLIFIED_MARKETS.includes(mc)

    const secondComponent = getNestedVals(data, ['page', 'components', '[2]'], null)
    const thirdComponent = getNestedVals(data, ['page', 'components', '[3]'], null)
    const uniteComponents =
      secondComponent.slug === 'language-tile' &&
      secondComponent.sectionTitle &&
      thirdComponent.rendering === 'funneling-sub-tiles' &&
      thirdComponent.sectionTitle === ''

    const notFunnelingTiles = secondComponent.rendering !== 'funneling-tiles'
    const isRu = mc === 'ru'
    const sectionTitleFromLabel = getLabel(serviceLabels, 'view-programs-by-type')
    return (
      <section
        id={`omnidiv-funneling-bigtile`}
        className={`${
          uniteComponents || firstComponentLanguage ? 'ef-section' : ''
        } rendering__${rendering} funneling-section ${checkTabletPosition ? ' -m-6 -l-12' : ''}`}
      >
        <div className={`cefcom-container  ${checkTabletPosition ? '-tablet-position ' : ''}`}>
          {(secondComponent.sectionTitle && notFunnelingTiles) ||
          (isLanguageTile && isRu) ||
          (!isLanguageTile && notFunnelingTiles) ? (
            ' '
          ) : (
            <>
              {sectionTitleFromLabel && (
                <h2 className='ef-section-main-title'>{sectionTitleFromLabel}</h2>
              )}
            </>
          )}

          {firstComponentLanguage && (
            <h2 className='ef-section-main-title'>{sectionTitleFromLabel}</h2>
          )}

          {rendering === 'funneling-tiles' && !firstComponentLanguage && (
            <h2 className='ef-section-main-title'>{thirdComponent.sectionTitle || null}</h2>
          )}

          <GridRow classNames={['funneling-tiles']}>
            {tiles.map((tile, i) => (
              <div key={i} className={`cefcom-col -s-12`}>
                <div id={`tile-${i}`}>
                  <FunnelingCard
                    {...tile}
                    rendering={rendering}
                    uniteComponents={uniteComponents}
                  />
                </div>
              </div>
            ))}
          </GridRow>
        </div>
      </section>
    )
  }
}
const mapStateToProps = (state) => ({
  serviceLabels: selectServiceLabels(state),
})

export default connect(mapStateToProps)(FunnelingComponent)
