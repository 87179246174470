import React, { useEffect } from 'react'
import { getNestedVals } from '@ilc-technology/cefcom-utils'
import { ProgramCardHorizontal } from '@ilc-technology/cefcom-program-card'
import { isRecentlyVisitedProgram } from '../../utils'

interface IOptimizelyUseProgramCardsProps {
  showRecentlyVisitedTag?: boolean
  recentlyVisitedTagLabel?: string
  optimizely?: {
    decision?: {
      variationKey?: string
    }
  }

  showAgeDifferentiator
  // isRecentlyVisitedTag
  card
  recentPrograms
  // ageGroupFilter
  // programs
  selectedAgeGroup
}

const OptimizelyUseProgramCardsWrapper: React.FC<IOptimizelyUseProgramCardsProps> = (props) => {
  const optimizelyUseTabsAsFilters =
    getNestedVals(props, ['optimizely', 'decision', 'variationKey'], 'off') === 'on'

  const {
    card,
    recentPrograms,
    // ageGroupFilter,
    // programs,
    showAgeDifferentiator,
    selectedAgeGroup,
  } = props

  return optimizelyUseTabsAsFilters
    ? card.map((program, i) => {
        const { slug, isProgramCard } = program
        const isRecent = isRecentlyVisitedProgram(program.code, recentPrograms)
        const { code } = program
        const compareProgramCode =
          program.agePrograms &&
          program.agePrograms.length &&
          program.agePrograms[0].code === selectedAgeGroup.code
        return (
          <React.Fragment key={i}>
            {isProgramCard ? (
              <ProgramCardHorizontal
                {...program}
                showAgeDifferentiator={showAgeDifferentiator}
                showRecentlyVisitedTag={isRecent}
                recentlyVisitedTagLabel={program.recentlyVisitedTagLabel}
                siteUrl={compareProgramCode ? program.agePrograms[0].siteUrl : program.siteUrl}
                classNames={`card card-${code.toLowerCase()}`}
              />
            ) : null}
          </React.Fragment>
        )
      })
    : card.map((program, i) => {
        const { isProgramCard } = program
        const { code } = program

        return (
          <React.Fragment key={i}>
            {isProgramCard ? (
              <ProgramCardHorizontal
                {...program}
                showAgeDifferentiator={showAgeDifferentiator}
                classNames={`card card-${code.toLowerCase()}`}
              />
            ) : null}
          </React.Fragment>
        )
      })
}

export default OptimizelyUseProgramCardsWrapper
