import {
  OPEN_PROGRAMS_POPUP,
  CLOSE_PROGRAMS_POPUP,
  SELECT_PROGRAM_CODE,
  POPUP_SLUG, HOMEPAGE_SLUG,
  } from '../constants';
import Cookie from 'js-cookie';
// import {requestService } from 'ef-ssr-resolver';
import {setHeaderTransparency } from '../../utils';

export const initPage = data => ({
  type: "INIT_PAGE",
  payload: data
})

export const openProgramsPopup = (programCode, transparentStatus) => (dispatch, getState) => {

  // Push the progCode in the url hash
  window.history.pushState({}, '', `#${POPUP_SLUG}${programCode}`);
  // Toggle the header transparency
  setHeaderTransparency(false);
  dispatch({
    type: OPEN_PROGRAMS_POPUP
  });
};

export const closeProgramsPopup = (transparentStatus) => (dispatch, getState) => {

  // Push homepage in the url hash
  window.history.pushState({}, '', `#${HOMEPAGE_SLUG}`);
  // Toggle the header transparency
  setHeaderTransparency(transparentStatus);

  dispatch({
    type: CLOSE_PROGRAMS_POPUP
  });

};
export const setZeusCookie = (code) => (dispatch, getState) => {
  if(!code){
    return
  }
  const ageGroupCode = code.toLowerCase() === 'all' ||  code.toLowerCase() === 'pg-all' ? 'all_user-defined': code.toLowerCase()
  // Set the cookie with js-cookie (Cookie)
  // by passing the name of the cookie and the value (code)
  Cookie.set('age_group', ageGroupCode ); //Cookie.set('ZEUS_SESSION_AGEGROUPCODE', code);

  // Dispatch this redux action
  dispatch({
    type: 'SET_ZEUS_COOKIE'
  });
};


export const selectProgramCode = (code, programCodes, popupSection, showAgeDifferentiator) => (dispatch, getState) => {
  dispatch(setZeusCookie(code));
  dispatch(openProgramsPopup(code));

  dispatch({
    type: SELECT_PROGRAM_CODE,
    code,
    programCodes,
    popupSection,
    showAgeDifferentiator
  });
};