import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectProgramCode } from '../../redux/actions'
import { TeaserCard } from '@ilc-technology/cefcom-teaser-card'
import { AGE_PROGRAM_SECTION_ID } from '../../redux/constants'
import { getNestedVals } from '@ilc-technology/cefcom-utils'
import { GridCol, GridRow, GridContainer } from '@ilc-technology/cefcom-ui-library'

import { IUSTiles } from '../../../types'
import './styles.scss'

class USTravel extends Component<IUSTiles> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      data,
      content,
      content: { slug, sectionTitle, rendering, subTiles },
    } = this.props
    const checkStageType = data.page.components[0].rendering
    const stageTypeTextOnly = 'stage-text-only'
    const e1Set1 = 'e1-sub-tiles-set-1'
    const e1Set1Buttons = slug === 'e1-sub-tiles-set-1-w-buttons'
    const marketCN = getNestedVals(data, ['app', 'site', 'marketCode'], null) === 'CN'
    const setForCN = marketCN && e1Set1Buttons
    const imgheightDefault = '400'
    const imgHeightMobile = rendering === 'tiles-w-buttons' ? '327' : '204'
    const imgWidthDefault = rendering === 'tiles-w-buttons' ? '384' : '588'
    const imgWidthMobile = '377'

    return (
      <div
        className={`us-tour-wrapper ${slug === e1Set1 || e1Set1Buttons ? '-set-tiles' : ''} ${
          marketCN && e1Set1Buttons ? '-cn-set-tiles' : ''
        }`}
        id={`us-${AGE_PROGRAM_SECTION_ID}`}
      >
        <section id={`omnidiv-${rendering}`} className={`ef-section rendering__${rendering}`}>
          <GridContainer
            classNames={[`${checkStageType !== stageTypeTextOnly ? '' : 'no-margin'}`]}
          >
            {sectionTitle && (
              <GridRow classNames={['-x-center']}>
                <h2 className='ef-section-main-title'>{sectionTitle}</h2>
              </GridRow>
            )}
            <GridRow
              classNames={[
                'us-tour',
                `${setForCN ? '' : '-x-center'}`,
                `${rendering === 'tiles-w-buttons' ? '-tiles-w-buttons' : ''}`,
              ]}
            >
              {subTiles.map((subTile, i) => {
                return (
                  <GridCol
                    classNames={[
                      'age-group-card__wrapper',
                      '-s-12',
                      '-m-6',
                      `${setForCN ? '-m-left -l-4' : ''}`,
                    ]}
                    key={i}
                  >
                    <div className='age-group-card'>
                      <TeaserCard
                        {...subTile}
                        heightDefault={imgheightDefault}
                        heightMobile={imgHeightMobile}
                        widthDefault={imgWidthMobile}
                        widthMobile={imgWidthDefault}
                        dataClickSubregion='program-by-category'
                      />
                    </div>
                  </GridCol>
                )
              })}
            </GridRow>
          </GridContainer>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  selectProgramCode: (code, programCodes) => dispatch(selectProgramCode(code, programCodes)),
})

export default connect(mapStateToProps, mapDispatchToProps)(USTravel)
