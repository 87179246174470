import React, { Component } from 'react';
import {FatFooter} from '@ilc-technology/cefcom-ui-library';
import { IServiceLabel } from '../../../types';
import './styles.scss';

interface IFatFooter {
    data: string[],
    content: {
      rendering: string
      title: string, 
      description: string, 
      anchorText: string,
      anchorUrl: string,
      images: string[],
      serviceLabels: IServiceLabel[]
    }
}

class FatFooterRendering extends Component<IFatFooter>{
   render() {
    const { content, content: {title, rendering, description, anchorText, anchorUrl, serviceLabels, images, backgroundImages} } = this.props;
     return (
      <div data-clicksubregion="omnidiv-fat-footer" id={`omnidiv-${rendering}`} className={`ef-section rendering rendering__${rendering}`}>
        <FatFooter
          title={title}
          description={description}
          label={serviceLabels}
          classNames={[`${rendering !== 'fat-footer-with-background-and-logo' ? '--theme-ef-yellow' : ''}`]}
          anchorUrl={anchorUrl}
          anchorText={anchorText}
          images={images}
          backgroundImages={backgroundImages}
        />
      </div>
    );
  }
}

export default FatFooterRendering;