import React, { Component } from 'react'
import { GridContainer, GridRow } from '@ilc-technology/cefcom-ui-library'
import { IHighlights } from '../../../types'
import './styles.scss'

class IkeaText extends Component<IHighlights> {
  render() {
    const {
      content: { title, rendering, description },
    } = this.props
    return (
      <section
        id={`omnidiv-${rendering}`}
        className={`ef-section rendering__${rendering} ikea-text-section`}
      >
        <GridContainer>
          <GridRow>
            <div className='block-text'>
              <h2 dangerouslySetInnerHTML={{ __html: title }} className='block-text__title' />
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </GridRow>
        </GridContainer>
      </section>
    )
  }
}

export default IkeaText
