import React from 'react';
import { Helmet } from 'react-helmet';

const PreloadedFonts = ({ mc }) => (
    <Helmet>
        {/* EF Circular Latin */}
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-Book.woff2" type="font/woff2" crossOrigin='anonymous' />
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-Light.woff2" type="font/woff2" crossOrigin='anonymous' />
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-LightItalic.woff2" type="font/woff2" crossOrigin='anonymous' />
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-BookItalic.woff2" type="font/woff2" crossOrigin='anonymous' />
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-Medium.woff2" type="font/woff2" crossOrigin='anonymous' />
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-MediumItalic.woff2" type="font/woff2" crossOrigin='anonymous' />
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-Bold.woff2" type="font/woff2" crossOrigin='anonymous' />
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-BoldItalic.woff2" type="font/woff2" crossOrigin='anonymous' />
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-Black.woff2" type="font/woff2" crossOrigin='anonymous' />
        <link rel="preload" as="font" href="/assetscdn/WIBIwq6RdJvcD9bc8RMd/central-media/common/fonts/ef-circular/EFCircularWeb-BlackItalic.woff2" type="font/woff2" crossOrigin='anonymous' />

        {/* Remove Google Fonts due to GDPR issue: https://ef-ilc.atlassian.net/browse/CONTENT-21810  */}

        {/* had to separate each <link for arabic as it was not rendering correctly */}
        {mc === 'wwar' || mc === 'sa' || mc === 'ae' || mc === 'ly' ?
            <link rel="preload" as="font" href="https://cdnaws.ef.design/libs/web-ui/latest/assets/fonts/notosansarabic-light.woff2" type="font/woff2" crossOrigin='anonymous' />
        : ''}
        {mc === 'wwar' || mc === 'sa' || mc === 'ae' || mc === 'ly' ?
            <link rel="preload" as="font" href="https://cdnaws.ef.design/libs/web-ui/latest/assets/fonts/notosansarabic-regular.woff2" type="font/woff2" crossOrigin='anonymous' />
        : ''}
        {mc === 'wwar' || mc === 'sa' || mc === 'ae' || mc === 'ly' ?
            <link rel="preload" as="font" href="https://cdnaws.ef.design/libs/web-ui/latest/assets/fonts/notosansarabic-bold.woff2" type="font/woff2" crossOrigin='anonymous' />
        : ''}
        {mc === 'jp' &&
            <link rel="preload" href={"https://fonts.gstatic.com/s/notosansjp/v25/-F6pfjtqLzI2JPCgQBnw7HFQaioq131nj-pXANNwpfqCt9pay6XIBdsAJNIhVEwQ.0.woff2"} type="text/css" />
        }
        {mc === 'kr' &&
            <link rel="preload" href={"https://fonts.gstatic.com/s/notosanskr/v12/Pby7FmXiEBPT4ITbgNA5CgmOelzI7bgWsWdx3Ch_M9uykRdOOoSdBjU92zqHxA.0.woff2"} type="text/css" />
        }
    </Helmet>
);

export default PreloadedFonts;
