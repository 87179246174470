import React, { Component } from 'react'
import { connect } from 'react-redux'
import FunnelingCardPopUp from './FunnelingCardPopUp'
import { selectProgramCode } from '../../redux/actions'
import { selectServiceLabels } from '../../redux/selectors'
import { LANGTILE_PROGRAM_SECTION_ID } from '../../redux/constants'
import { getLabel } from '@ilc-technology/cefcom-utils'
import { IFunnelingCard } from '../../../types'
import { GridRow } from '@ilc-technology/cefcom-ui-library'
import './styles.scss'

class LanguageTilePopUp extends Component<IFunnelingCard> {
  constructor() {
    super()
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(subTile) {
    const { code, programCodes } = subTile
    const upperProgramCodes = programCodes.toUpperCase()
    this.props.selectProgramCode(code, upperProgramCodes)
  }

  render() {
    const {
      data,
      content,
      content: { sectionTitle, rendering, subTiles },
      serviceLabels,
    } = this.props
    const langTileSingleLink =
      subTiles && subTiles[0] && subTiles[0].subRendering === 'tile-lang-single-link'
    const hasFirstSubTileComponent = data.page.components[1]
    const secondComponent = data.page.components[2]

    return (
      <div className='' id={LANGTILE_PROGRAM_SECTION_ID}>
        <section
          id={`omnidiv-funneling-bigtile`}
          className={`rendering__${rendering} rendering__funneling-tiles funneling-section ${
            hasFirstSubTileComponent ? '-m-6 -l-12' : ''
          }`}
        >
          <div
            className={`cefcom-container  ${hasFirstSubTileComponent ? '-tablet-position ' : ''}`}
          >
            {secondComponent.sectionTitle && secondComponent.rendering !== 'funneling-tiles' ? (
              ''
            ) : (
              <h2 className='ef-section-main-title'>
                {getLabel(serviceLabels, 'view-programs-by-type')}
              </h2>
            )}
            <GridRow className='funneling-tiles'>
              {subTiles.map((tile, i) => (
                <div key={`${tile.slug}-${i}`} className={`cefcom-col -s-12`}>
                  <div id={`tile-${i}`}>
                    <FunnelingCardPopUp
                      {...tile}
                      subTile={tile}
                      description={tile.hoverSection.hoverDescription}
                      rendering={rendering}
                    />
                  </div>
                </div>
              ))}
            </GridRow>
          </div>
        </section>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  serviceLabels: selectServiceLabels(state),
})

const mapDispatchToProps = (dispatch) => ({
  selectProgramCode: (code, programCodes) => dispatch(selectProgramCode(code, programCodes)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LanguageTilePopUp)
