import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GridCol, GridRow, GridContainer } from '@ilc-technology/cefcom-ui-library'
import { selectProgramCode } from '../../redux/actions'
import { TeaserCard } from '@ilc-technology/cefcom-teaser-card'
import { getAvailableGridCols } from '../../utils/columnChecker'
import { NotSureCard } from '@ilc-technology/cefcom-teaser-card'
import { AGE_PROGRAM_SECTION_ID } from '../../redux/constants'
import { IAgeGroup } from '../../../types'
import { getLabel, getNestedVals, imageAdapter } from '@ilc-technology/cefcom-utils'
import { selectServiceLabels } from '../../redux/selectors'

import './styles.scss'

class AgeCategories extends Component<IAgeGroup> {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(ageCategory) {
    const { code, programCodes } = ageCategory
    const upperProgramCodes = programCodes.toUpperCase()
    this.props.selectProgramCode(code, upperProgramCodes)
  }

  render() {
    const {
      data,
      data: { mc, programcards },
      content,
      content: { title, rendering, programCodes, showPopup },
    } = this.props
    const { ageGroupCategories } = programcards

    const serviceLabels = getNestedVals(data, ['page', 'serviceLabels'], null)
    let counter = 0
    const imgheightDefault = '256'
    const imgHeightMobile = '204'
    const imgWidthDefault = '384'
    const imgWidthMobile = '377'
    return (
      <div id={AGE_PROGRAM_SECTION_ID}>
        <section id={`omnidiv-${rendering}`} className={`ef-section rendering__${rendering}`}>
          <GridContainer>
            <GridRow classNames={['-x-center']}>
              <h2 className='ef-section-main-title'>{title}</h2>
            </GridRow>
            <GridRow classNames={['age-categories', '-x-center']}>
              {ageGroupCategories.map((ageCategory, i) => {
                if (ageCategory.slug.toLowerCase() === 'pg-all') {
                  // Skip rendering and counting for 'PG-ALL'
                  return null
                }

                counter += ageCategory.slug.toLowerCase() !== 'all' ? 1 : 0
                if (ageCategory.slug.toLowerCase() !== 'all') {
                  return (
                    <GridCol
                      classNames={['age-group-card__wrapper', '-s-12', '-m-6', '-l-4']}
                      key={i}
                    >
                      <div
                        className='age-group-card'
                        onClick={(e) => {
                          e.preventDefault()
                          this.handleClick(ageCategory)
                        }}
                      >
                        <TeaserCard
                          {...ageCategory}
                          programFor={getLabel(serviceLabels, 'programs-for')}
                          anchorUrl={`#${ageCategory.code}`}
                          heightDefault={imgheightDefault}
                          heightMobile={imgHeightMobile}
                          widthDefault={imgWidthMobile}
                          widthMobile={imgWidthDefault}
                          dataClickSubregion='program-by-age'
                          images={imageAdapter(ageCategory.images)}
                        />
                      </div>
                    </GridCol>
                  )
                } else {
                  return (
                    <GridCol
                      key={i}
                      classNames={[
                        'age-group-card__wrapper',
                        getAvailableGridCols(counter, true).s,
                        getAvailableGridCols(counter, true).m,
                        getAvailableGridCols(counter, true).l,
                      ]}
                    >
                      {ageCategory.showPopup ? (
                        <div
                          className={`age-group-card nsc${
                            getAvailableGridCols(counter, true).m
                          } nsc${getAvailableGridCols(counter, true).l}`}
                          onClick={(e) => {
                            e.preventDefault()
                            this.handleClick(ageCategory)
                          }}
                        >
                          <NotSureCard
                            {...ageCategory}
                            siteUrl={ageCategory.anchorUrl}
                            siteBtnLabel={ageCategory.anchorText}
                            anchorUrl={`#${ageCategory.code}`}
                            dataClickSubregion='program-by-age'
                          />
                        </div>
                      ) : (
                        <NotSureCard
                          {...ageCategory}
                          siteUrl={`#${ageCategory.code}`}
                          siteBtnLabel={ageCategory.anchorText}
                          anchorUrl={`#`}
                          dataClickSubregion='program-by-age'
                        />
                      )}
                    </GridCol>
                  )
                }
              })}
            </GridRow>
          </GridContainer>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  serviceLabels: selectServiceLabels(state),
})

const mapDispatchToProps = (dispatch) => ({
  selectProgramCode: (code, programCodes) => dispatch(selectProgramCode(code, programCodes)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AgeCategories)
