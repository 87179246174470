/*
 ************************************************************************************************
 * Keys and IDs
 ************************************************************************************************
 */

export const GTM_ID = 'GTM-K57G4W'

export const SIMPLIFIED_MARKETS = ['gr', 'il', 'az', 'ro', 'lv', 'si', 'hu']
export const defaultAgeGroupCode = 'all_default'
export const userDefinedAllCode = 'all_user-defined'


export const AGE_GROUP_SELECTION_COOKIE = 'age_group' // old ZEUS
export const SPLASH_COOKIE = 'splash_page'
export const PAID_SEARCH_NO_SHOW_COOKIE = 'NO_SHOW'
export const CHANGE_AGE_GROUP_FILTER = 'CHANGE_AGE_GROUP_FILTER'


export const FEATURE_TAGS_QUERY =
  'TagTopic_TagAgeRange_TagWhen_TagDuration_TagFeature_TagLanguageLearned_TagPlatform_TagTerritory_TagCityState'
export const DRILLDOWN_NAMES = [
  'cefcom-ui-section-drilldown',
  'cefcom-ui-section-drilldown-customized',
  'cefcom-ui-section-destination-carousel',
  'cefcom-ui-section-external-links',
]

export const DATASOURCE_COUNTRY_LANGUAGE_MAPPING = 'country-language-mapping'
export const DATASOURCE_NAME_FOR_SORT_BY_CITY = 'city-sort-order-by-territory'
export const DATASOURCE_NAME_FOR_SORT_BY_COUNTRY = 'country-sort-order-by-market'
export const DATASOURCE_NAME_FOR_SORT_BY_LANGUAGE = 'language-sort-order-by-market'

