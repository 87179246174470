import React, { Component } from 'react'
import { LazyResponsiveImage } from '@ilc-technology/cefcom-ui-library'
import { IFunnelingCardTile } from '../../../../types'

class FunnelingCard extends Component<IFunnelingCardTile> {
  constructor(props) {
    super(props)

    this.state = {
      clicked: false,
      minHeight: 204,
    }

    this.addClass = this.addClass.bind(this)
    this.removeClass = this.removeClass.bind(this)
  }

  addClass() {
    const height = this.divElement.clientHeight
    this.setState({
      clicked: true,
      minHeight: height,
    })
  }

  removeClass() {
    this.setState({
      clicked: false,
    })
  }

  render() {
    const {
      title,
      subTitle,
      images,
      description,
      studyTypes,
      tileAnchorText,
      tileAnchorUrl,
      tileAnchorDescription,
      subRendering,
      isExternalLink,
      uniteComponents,
      slug,
    } = this.props

    const imgheightDefault = '450'
    const imgHeightMobile = '204'
    const imgWidthDefault = '1200'
    const imgWidthMobile = '377'
    {
      /* //{uniteComponents ? '' : 'ef-section'} */
    }
    return (
      <div className={`default-tile-wrapper`}>
        <a
          href={tileAnchorUrl}
          target={`${isExternalLink ? '_blank' : '_self'}`}
          rel={`${isExternalLink ? 'noopener' : ''}`}
          data-clicksubregion='program-by-category'
        >
          <div
            className={`default-tile --language --simplified`}
            data-info={slug}
            data-rendering={subRendering}
          >
            <LazyResponsiveImage
              alt={title}
              classNames={['ef-card--teaser__img']}
              responsiveImages={images}
              heightDefault={imgheightDefault}
              heightMobile={imgHeightMobile}
              widthDefault={imgWidthMobile}
              widthMobile={imgWidthDefault}
            />
            <div className='default-tile__content'>
              {title && (
                <h2 className='default-tile__title' dangerouslySetInnerHTML={{ __html: title }} />
              )}
              {description && (
                <div
                  className='default-tile__description'
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
          </div>
        </a>
      </div>
    )
  }
}

export default FunnelingCard
