import React, { Component } from 'react'
import { GridCol } from '@ilc-technology/cefcom-ui-library'
import { connect } from 'react-redux'
import { selectProgramCode } from '../../redux/actions'
import { LazyResponsiveImage } from '@ilc-technology/cefcom-ui-library'
import { ISubTile } from '../../../types'
class SubTile extends Component<ISubTile> {
  constructor(props) {
    super(props)

    this.state = {
      clickedSubTile: false,
    }
    this.addClass = this.addClass.bind(this)
    this.removeClass = this.removeClass.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  addClass() {
    this.setState({
      clickedSubTile: true,
    })
  }
  removeClass() {
    this.setState({
      clickedSubTile: false,
    })
  }

  handleClick(subTile) {
    const { code, programCodes } = subTile
    const upperProgramCodes = programCodes.toUpperCase()
    this.props.selectProgramCode(code, upperProgramCodes)
  }

  render() {
    const { subTile } = this.props

    const imgheightDefault = '256'
    const imgHeightMobile = '204'
    const imgWidthDefault = '384'
    const imgWidthMobile = '377'
    return (
      <GridCol classNames={['-s-12', '-m-6', '-l-4']}>
        <div className={`default-tile-wrapper`}>
          <a
            href={subTile.anchorUrl}
            target={`${subTile.isExternal ? '_blank' : '_self'}`}
            rel={`${subTile.isExternal ? 'noopener' : ''}`}
            data-clicksubregion='program-by-category'
          >
            <div
              className={`default-tile`}
              data-info={subTile['slug']}
              data-rendering={subTile.rendering}
            >
              <LazyResponsiveImage
                alt={subTile.title}
                classNames={['ef-card--teaser__img']}
                responsiveImages={subTile.images || []}
                heightDefault={imgheightDefault}
                heightMobile={imgHeightMobile}
                widthDefault={imgWidthMobile}
                widthMobile={imgWidthDefault}
              />
              <div className='default-tile__content'>
                {subTile.title && (
                  <h2
                    className='default-tile__title'
                    dangerouslySetInnerHTML={{ __html: subTile.title }}
                  />
                )}
                {subTile.hoverSection.hoverDescription && (
                  <div
                    className='default-tile__description'
                    dangerouslySetInnerHTML={{ __html: subTile.hoverSection.hoverDescription }}
                  />
                )}
              </div>
            </div>
          </a>
        </div>
      </GridCol>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  selectProgramCode: (code, programCodes) => dispatch(selectProgramCode(code, programCodes)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubTile)
