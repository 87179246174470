import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectProgramCode } from '../../redux/actions'
import { TeaserCard } from '@ilc-technology/cefcom-teaser-card'
import { IUSOverview } from '../../../types'
import { GridContainer, GridCol, GridRow } from '@ilc-technology/cefcom-ui-library'
import './styles.scss'

class USOverview extends Component<IUSOverview> {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      content,
      content: { pageTitle, pageDescription, rendering, tiles },
    } = this.props
    const { subTiles, sectionTitle } = tiles[0]

    const imgheightDefault = '256'
    const imgHeightMobile = '204'
    const imgWidthDefault = '384'
    const imgWidthMobile = '377'

    return (
      <React.Fragment>
        <section
          id={`omnidiv-${rendering}`}
          className={`ef-section rendering__${rendering} pg-jump-page-section`}
        >
          <div className={`program-cards-top`}>
            <GridContainer>
              <GridRow classNames={['program-cards__header-row']}>
                <GridCol classNames={['-sm-12', 'program-cards__header-col']}>
                  <div className='program-cards__header'>
                    <h2
                      className='program-cards__header-title'
                      dangerouslySetInnerHTML={{ __html: pageTitle }}
                    />
                    <p
                      className='program-cards__header-label'
                      dangerouslySetInnerHTML={{ __html: pageDescription }}
                    />
                  </div>
                </GridCol>
              </GridRow>
            </GridContainer>
          </div>
          <div className='pg-jump-page section-wrapper us-funneling-section'>
            <GridContainer>
              {sectionTitle && (
                <GridRow classNames={['-x-center']}>
                  <h2 className='ef-section-main-title'>{sectionTitle}</h2>
                </GridRow>
              )}
              <GridRow>
                {subTiles.map((subTile, i) => {
                  return (
                    <GridCol
                      classNames={['age-group-card__wrapper', '-s-12', '-m-6', '-l-4']}
                      key={i}
                    >
                      <div className='age-group-card'>
                        <TeaserCard
                          {...subTile}
                          heightDefault={imgheightDefault}
                          heightMobile={imgHeightMobile}
                          widthDefault={imgWidthMobile}
                          widthMobile={imgWidthDefault}
                          dataClickSubregion='program-by-category'
                        />
                      </div>
                    </GridCol>
                  )
                })}
              </GridRow>
            </GridContainer>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  selectProgramCode: (code, programCodes) => dispatch(selectProgramCode(code, programCodes)),
})

export default connect(mapStateToProps, mapDispatchToProps)(USOverview)
