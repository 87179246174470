import React, { Component } from 'react'
import Accordion from './Accordion'
interface IColumn {
  title: string
  links: string[]
  isStacked: boolean
}
class Column extends Component<IColumn> {
  constructor(props) {
    super(props)

    this.state = {
      mobileTab1: 'closed',
      mobileTab2: 'closed',
      mobileTab3: 'closed',
      mobileTab4: 'closed',
      mobileTab5: 'closed',
      mobileTab6: 'closed',
    }

    this.toggleTab = this.toggleTab.bind(this)
  }

  toggleTab(e) {
    e.preventDefault()

    // console.log(e);

    // console.log(this.state.mobileTab == 'closed' ? 'open' : 'closed');
    this.setState({
      mobileTab1: this.state.mobileTab == 'closed' ? 'open' : 'closed',
    })
  }

  render() {
    const { title, links, content } = this.props
    const { isStacked, stackedLinks } = content
    return (
      <React.Fragment>
        {title === '' ? (
          ''
        ) : (
          <div className={`gpf-col ${isStacked ? '-stacked-links' : ''}`}>
            <Accordion title={title}>
              {links
                ? links.map((link, i) => (
                    <li>
                      <a href={link.anchorUrl}>{link.title}</a>
                    </li>
                  ))
                : null}
            </Accordion>
            {isStacked && stackedLinks
              ? stackedLinks.map((stacked, i) => (
                  <Accordion key={i} title={stacked.title}>
                    {stacked.links
                      ? stacked.links.map((link, i) => (
                          <li>
                            <a href={link.anchorUrl}>{link.title}</a>
                          </li>
                        ))
                      : null}
                  </Accordion>
                ))
              : null}
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default Column
