import React from 'react'
import { ProgramCardHorizontal } from '@ilc-technology/cefcom-program-card'
import { GridContainer, GridRow } from '@ilc-technology/cefcom-ui-library'

const AvailableProgramsSeo = ({ availableProgramCards }) => {
  return (
    <div style={{ display: 'none' }}>
      <section
        id={`omnidiv-available-programs`}
        className={`ef-section rendering__available-programs`}
      >
        <GridContainer>
          <GridRow>
            {availableProgramCards.map((card, i) => {
              return (
                <React.Fragment key={card.code}>
                  <ProgramCardHorizontal
                    {...card}
                    siteBtnLabel={card.anchorText}
                    brochureBtnLabel={card.brochureAnchorText}
                    showAgeDifferentiator={true}
                  />
                </React.Fragment>
              )
            })}
          </GridRow>
        </GridContainer>
      </section>
    </div>
  )
}

export default AvailableProgramsSeo
