import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './styles.scss';
interface IAccordion {
  title: string,
  anchorUrl: string
}
class Accordion extends Component<IAccordion>{
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      height: '0',
      class: ''
    };

    this.handleClick = this.handleClick.bind(this);
    this.checkListHeight = this.checkListHeight.bind(this);
  }

  componentDidMount() {
    this.checkListHeight();

    window.addEventListener('resize', this.checkListHeight);
  }

  checkListHeight() {
    const el = ReactDOM.findDOMNode(this);
    const height = el.parentNode.querySelector('ul').scrollHeight;
    this.setState({
      height
    });
  }

  handleClick() {
    if (this.state.open) {
      this.setState({
        open: false,
        class: ''
      });
    } else {
      this.setState({
        open: true,
        class: 'expanded'
      });
    }
  }

  render() {
    const { title } = this.props;
    const { height } = this.state;

    const innerStyle = {
      height: `${this.state.open ? height : 0}px`
    };

    return (
      <React.Fragment>
        <h3 onClick={this.handleClick} className={this.state.class + ' prefooter-col_heading'}>
          {title}
        </h3>
        <ul style={innerStyle}>{this.props.children}</ul>
      </React.Fragment>
    );
  }
}

export default Accordion;
