import { createSelector } from 'reselect'
import { mapOrder } from '../../utils'

// Selectors

export const configSelector = (state) => state.config
export const serviceDataSelector = (state) => (state.serviceData ? state.serviceData : {})
// export const headerDataSelector        = state => state.headerData;
// export const footerDataSelector        = state => state.footerData;
export const changeCountryDataSelector = (state) => state.changeCountryData
export const currentURLSelector = (state, match) => match.params.page
export const selectPageRendering = (state) =>
  state.serviceData && state.serviceData.pageId ? state.serviceData.pageId : ''
export const isProgramsPopUpOpen = (state) => state.isPopUpOpen

export const filteredProgramCodes = (state) => {
  return state.filteredProgramCodes
}
export const selectedProgramCodeSelector = (state) => state.programCode
export const showAgeDifferentiator = (state) => state.showAgeDifferentiator

// ***********************************************************
// Composed Memoized Selectors
// ***********************************************************

// Use "createSelector" to compose selectors and create memoized/cached versions of selectors to improve performance
// since they will not change unless the portion of the store they are using changes

export const pageDataSelector = createSelector(serviceDataSelector, (data) => data.page || {})
export const programCardsSelector = createSelector(
  serviceDataSelector,
  (data) => data.programcards || {},
)
// export const metaDataSelector = createSelector(serviceDataSelector, data => data.meta || {});
export const appDataSelector = createSelector(serviceDataSelector, (data) => data.app || {})
export const siteDataSelector = createSelector(appDataSelector, (data) => data.site || {})

// export const programCardPositionSelector = createSelector(appDataSelector, data => data.site.programCardPosition || {});

// BasePath
export const basePathSelector = createSelector(configSelector, (config) => config.basePath)

// FullPath
export const fullPathSelector = createSelector(configSelector, (config) => config.fullPath)

// Host
export const hostSelector = createSelector(configSelector, (config) => config.host)

// Service
export const servicePathSelector = createSelector(configSelector, (config) => config.servicePath)

// Google API Key
export const googleApiKeySelector = createSelector(
  siteDataSelector,
  (site) => site.googleApiKey || null,
)

// Market
export const marketSelector = createSelector(configSelector, (config) => config.mc)

// isChina
export const isChinaSelector = createSelector(marketSelector, (mc) => mc === 'CN')

export const pageStyleSelector = createSelector(
  pageDataSelector,
  (pageData) => pageData.headerStyle,
)

export const isPageStyleTransparentSelector = createSelector(
  pageStyleSelector,
  (style) => style === 'transparent',
)

export const isHeaderTransparent = createSelector(pageDataSelector, (pageData) => {
  return pageData && pageData.isTransparent && pageData.isTransparent.length
    ? pageData.isTransparent
    : []
})

// If page data is defined and serviceData.components key is a proper array return it
// otherwise instead of throwing errors return an empty array
// leaving the Component Retriever to manage wjat to do with the empty array
export const renderingsSelector = createSelector(pageDataSelector, (pageData) =>
  pageData && pageData.components && pageData.components.length ? pageData.components : [],
)

export const availableProgramsSelector = createSelector(programCardsSelector, (pageData) => {
  return pageData && pageData.availableProgramCards && pageData.availableProgramCards.length
    ? pageData.availableProgramCards
    : []
})

export const notSureCardSelector = createSelector(
  availableProgramsSelector,
  (availableProgramCards) => {
    if (!availableProgramCards || !availableProgramCards.length) {
      return {}
    }
    return availableProgramCards.find((o) => !o.code) || {}
  },
)
export const labelSelector = createSelector(siteDataSelector, (siteData) =>
  siteData && siteData.serviceLabels && siteData.serviceLabels.length ? siteData.serviceLabels : [],
)

export const selectServiceLabels = (state) =>
  state.serviceData &&
  state.serviceData.page &&
  state.serviceData.page &&
  state.serviceData.page.serviceLabels
    ? state.serviceData.page.serviceLabels
    : []

// export const ageGroupsSelector = createSelector(pageDataSelector, (pageData) =>
//   pageData && pageData.categoryPopups && pageData.categoryPopups.length
//     ? pageData.categoryPopups
//     : [],
// )

export const ageGroupsSelector = createSelector(programCardsSelector, (pageData) =>
  pageData && pageData.ageGroupCategories && pageData.ageGroupCategories.length
    ? pageData.ageGroupCategories
    : [],
)

export const subTileSelector = createSelector(pageDataSelector, (pageData) =>
  pageData && pageData.components
    ? pageData.components.filter((component) => component.slug === 'sub-tiles')[0]
    : null,
)

export const subTileSecondSelector = createSelector(pageDataSelector, (pageData) =>
  pageData && pageData.components
    ? pageData.components.filter((component) => component.slug === 'sub-tiles-2')[0]
    : null,
)
export const subTilePopUpSelector = createSelector(pageDataSelector, (pageData) =>
  pageData && pageData.components
    ? pageData.components.filter((component) => component.rendering === 'funneling-tile-pop-up')[0]
    : null,
)

export const subTileSecondSetSelector = createSelector(pageDataSelector, (pageData) =>
  pageData && pageData.components
    ? pageData.components.filter((component) => component.slug === 'e1-sub-tiles-set-2')[0]
    : null,
)
export const subTileE1Selector = createSelector(pageDataSelector, (pageData) =>
  pageData && pageData.components
    ? pageData.components.filter((component) => component.slug === 'e1-id-sub-tiles-by-type')[0]
    : null,
)
export const subTileE1WBtnsSelector = createSelector(pageDataSelector, (pageData) =>
  pageData && pageData.components
    ? pageData.components.filter((component) => component.slug === 'e1-id-sub-tiles-w-buttons')[0]
    : null,
)

export const langTileSecondSelector = createSelector(pageDataSelector, (pageData) =>
  pageData && pageData.components
    ? pageData.components.filter((component) => component.rendering === 'funneling-tile-pop-up')[0]
    : null,
)

// export const showAgeDifferentiatorSelector = createSelector(pageDataSelector, (pageData) =>
//   pageData && pageData.categoryPopups
//     ? pageData.categoryPopups.filter(
//         (categoryPopups) => categoryPopups.showAgeDifferentiator === true,
//       )[0]
//     : null,
// )
// export const differentLinkSelector = createSelector(pageDataSelector, (pageData) =>
//   pageData && pageData.categoryPopups
//     ? pageData.categoryPopups.filter((categoryPopups) => categoryPopups.code)[0]
//     : null,
// )

export const differentLinkSelector = createSelector(programCardsSelector, (pageData) =>
  pageData && pageData.ageGroupCategories
    ? pageData.ageGroupCategories.filter((ageGroupCategories) => ageGroupCategories.code)[0]
    : null,
)

export const selectedSubTileSelector = createSelector(
  selectedProgramCodeSelector,
  subTileSelector,
  subTileSecondSelector,
  subTilePopUpSelector,
  subTileSecondSetSelector,
  subTileE1Selector,
  subTileE1WBtnsSelector,
  langTileSecondSelector,
  (
    progCode,
    selectedSubTile,
    selectedSubTileSecond,
    selectedsubTileSecondSet,
    selectedSubTileE1Selector,
    selectedsubTileE1WBtnsSelector,
    selectedLangTile,
  ) => {
    if (
      progCode &&
      (selectedSubTile ||
        selectedSubTileSecond ||
        selectedsubTileSecondSet ||
        selectedSubTileE1Selector ||
        selectedsubTileE1WBtnsSelector ||
        selectedLangTile)
    ) {
      let foundTile = null

      ;[
        selectedSubTile,
        selectedSubTileSecond,
        selectedsubTileSecondSet,
        selectedSubTileE1Selector,
        selectedsubTileE1WBtnsSelector,
        selectedLangTile,
      ].forEach((selectedSubTileItem) => {
        if (selectedSubTileItem && selectedSubTileItem.subTiles) {
          const currentTile = selectedSubTileItem.subTiles.find((subTile) => {
            return subTile.code === progCode
          })

          if (currentTile !== undefined) {
            foundTile = currentTile
          }
        }
      })
      return foundTile
    } else {
      return {}
    }
  },
)

export const popUpHasSubtile = createSelector(selectedSubTileSelector, (subtile) =>
  subtile ? Object.keys(subtile).length > 0 : false,
)

export const selectedAgeGroupSelector = createSelector(
  [
    ageGroupsSelector,
    selectedProgramCodeSelector,
    // showAgeDifferentiatorSelector,
    differentLinkSelector,
  ],
  (ageGroups, progCode) => {
    try {
      const ageGroup = ageGroups.find((ageGroup) => progCode === ageGroup.code)
      return ageGroup ? ageGroup : {}
    } catch (error) {
      return {}
    }
  },
)

export const selectedTileGroupSelector = createSelector(
  [
    isProgramsPopUpOpen,
    subTileSelector,
    subTileSecondSelector,
    subTilePopUpSelector,
    subTileSecondSetSelector,
    subTileE1Selector,
    subTileE1WBtnsSelector,
    langTileSecondSelector,
    selectedProgramCodeSelector,
    // showAgeDifferentiatorSelector,
    differentLinkSelector,
  ],
  (tileGroups, progCode) => {
    try {
      const tileGroup = tileGroups.find((tileGroup) => progCode === tileGroup.code)
      return tileGroup ? tileGroup : {}
    } catch (error) {
      return {}
    }
  },
)

export const derivatedAvailableProgramsSelector = createSelector(
  [
    availableProgramsSelector,
    isProgramsPopUpOpen,
    filteredProgramCodes,
    selectedAgeGroupSelector,
    // showAgeDifferentiatorSelector,
    differentLinkSelector,
  ],
  (availableProgramCards, isOpen, filters, ageGroup) => {
    try {
      if (!availableProgramCards || !availableProgramCards.length) {
        return []
      }

      const filtersArr = filters.split(',')

      // Filter only program code cards (Remove Not Sure card)
      let derivedCards = availableProgramCards.filter((o) => o.code != null)

      // Sort by order
      derivedCards = mapOrder(derivedCards, filtersArr, 'code')

      // Add isVisible prop
      derivedCards = derivedCards.map((card) => {
        card.isVisible = filtersArr.includes(card.code)
        // card.showAgeDifferentiator;
        return card
      })

      // Filter by isVisible
      derivedCards = derivedCards.filter((c) => c.isVisible)

      // Return derivedVards or teh original canonical programs
      return derivedCards
    } catch (error) {
      return []
    }
  },
)
