/* eslint-disable no-console */
import React from 'react'
import DestinationsCarousel from '../../components/DestinationsCarousel'
import CefcomOptimizely from '../../../utils/hoc/CefcomOptimizely'
import OptimizelyUseDestinationCarousel from '../../containers/OptimizelyUseDestinationCarousel'

import './styles.scss'

const DrilldownDestinations = (props) => {
  const {
    content: { title, rendering, carouselContent },
    data,
  } = props
  const Filter = 'languageSortOrder'
  const dataSources = {
    'language-sort-order-by-market': [
      {
        value:
          'lan-eng|lan-spa|lan-fre|lan-ger|lan-ita|lan-chi|lan-jap|lan-dut|lan-kor|lan-pot|lan-ara',
      },
    ],
  }

  let parsedCarouselContent = null
  try {
    parsedCarouselContent = JSON.parse(carouselContent.toString())
  } catch (error) {
    console.error('Error parsing JSON string:', error)
  }
  const { tabs, cards } = parsedCarouselContent

  const languageOrder = dataSources['language-sort-order-by-market'][0].value.split('|')

  const filteredCards = cards.filter((card) => languageOrder.includes(card.tagLanguageLearned))

  const orderedCards = languageOrder
    .map((lang) => filteredCards.filter((card) => card.tagLanguageLearned === lang))
    .flat()

  const destinations = tabs.map((tab) => {
    const tabDestinations = orderedCards.filter(
      (card) => card.tagLanguageLearned === tab.tagLanguageLearned,
    )
    return {
      anchorText: '',
      anchorUrl: tab.anchorUrl,
      destinations: tabDestinations.map((dest) => {
        return {
          subTitle: dest.anchorText,
          url: dest.anchorUrl,
          images: dest.images,
          altTextImage: dest.anchorText,
        }
      }),
      noFilters: false,
      slug: tab.tagLanguageLearned,
      title: tab.anchorText,
    }
  })

  const drilldown = {
    isDestinationCarousel: true,
    defaultLang: 'lan-eng',
    items: destinations,
    title: title,
  }
  return (
    <CefcomOptimizely config={{ flagKey: 'hpdc01', centralData: data }}>
      <OptimizelyUseDestinationCarousel data={drilldown} rendering={rendering} centralData={data} />
    </CefcomOptimizely>
  )
}

export default DrilldownDestinations
